import {Button, Loader} from 'components';
import React from 'react';
import {useAITranlsation} from 'services/query/modules';
import {LanguageIcon, ClipboardIcon} from '@heroicons/react/24/outline';
import cx from 'classnames';
import {toast} from 'react-toastify';

type AITranslationProps = {
  className?: string;
  message: string;
  lang: string;
  handleValue?(text: string): void;
};

export const AITranslation: React.FC<AITranslationProps> = ({className, message, lang, handleValue}) => {
  const {mutate: getAITranslation, isSuccess, isLoading, data} = useAITranlsation();

  const handleTranslationClick = () => getAITranslation({message, lang});
  React.useEffect(() => {
    if (isSuccess) {
      handleValue?.(data[0]);
    }
  }, [isSuccess]);

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Copied');
    });
  };

  return (
    <div className={cx('flex justify-start w-full', className)}>
      <div className="flex flex-col gap-2 w-full">
        <h4>{isSuccess ? 'Translation Options' : 'AI Help'}</h4>
        {isSuccess ? (
          <div className="flex flex-col gap-1 w-full">
            {data.map((option) => (
              <div key={option} className="flex gap-4 items-start order p-6 w-full bg-white border-gray-200">
                <p className="flex-1 italic">{option}</p>
                <ClipboardIcon className="w-8 cursor-pointer hover:scale-110" onClick={() => handleCopyClick(option)} />
              </div>
            ))}
          </div>
        ) : (
          <Button onClick={handleTranslationClick} className="flex justify-center w-12 bg-gray-300">
            {isLoading ? <Loader className="w-4 [&>svg]:mr-0" /> : <LanguageIcon className="w-4" color="black" />}
          </Button>
        )}
      </div>
    </div>
  );
};
