import {useMutation, useQuery} from '@tanstack/react-query';
import {getSelf, invite} from 'services/axios/modules';

export const useSelf = () => {
  return useQuery(['me'], () => {
    return getSelf();
  });
};

export const useInvite = () => {
  return useMutation({
    mutationFn: (data: {email: string; firstName: string; lastName: string; role: string}) => {
      return invite(data);
    },
  });
};
