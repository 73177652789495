import {request} from '..';

export const uploadMethodAttachments = (formData: FormData) => {
  return request<ToolboxObject.IFile[]>({
    url: '/editable-methods/files',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
};

export const deleteMethodAttachments = (fileIds: string[]) => {
  return request<ToolboxObject.IFile[]>({
    url: '/editable-methods/files',
    method: 'DELETE',
    data: {fileIds},
  });
};

export const getFileUrl = (id: string) => {
  return request({
    url: `/files/${id}`,
  });
};
