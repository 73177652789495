import {useMutation, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {
  addWorkshopUsers,
  createRecipe,
  createWorkshop,
  deleteRecipe,
  deleteWorkshop,
  disconnectMiro,
  duplicateWorkshop,
  exportAsRecipe,
  getRecipe,
  getRecipeCategories,
  getRecipes,
  getWorkshop,
  getWorkshopAvailableUsers,
  getWorkshops,
  linkMiroBoardToWorkshop,
  miroBoardFromWorkshop,
  saveRecipe,
  saveWorkshop,
  toggleLikeRecipe,
  viewRecipe,
  viewWorkshop,
} from 'services/axios/modules';
import {queryClient} from '..';
import produce from 'immer';

export const useRecipeCategories = () => {
  return useQuery<ToolboxObject.WorkshopCategory[]>(
    ['recipeCategories'],
    () => {
      return getRecipeCategories();
    },
    {staleTime: 3600000, cacheTime: 3600000}
  );
};

export const useRecipes = (params?: ToolboxObject.FindWorkshopParams) => {
  return useQuery(
    ['recipes', params],
    () => {
      return getRecipes(params);
    },
    {staleTime: 3600000, cacheTime: 3600000}
  );
};

export const useWorkshops = () => {
  return useQuery(
    ['workshops'],
    () => {
      return getWorkshops();
    },
    {staleTime: 3600000, cacheTime: 3600000}
  );
};

export const useWorkshop = (id: number, recipe?: boolean) => {
  return useQuery(
    [recipe ? 'recipes' : 'workshops', id],
    () => {
      return recipe ? getRecipe(id) : getWorkshop(id);
    },
    {cacheTime: 0}
  );
};

export const useViewWorkshop = (workshopId: number) => {
  return useMutation({
    mutationFn: () => {
      return viewWorkshop(workshopId);
    },
    onSuccess: (data) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {last_viewed} = data;
      return queryClient.setQueryData(['workshops'], (oldWorkshops: ToolboxObject.Workshop[] | undefined) => {
        return produce(oldWorkshops, (draft: ToolboxObject.Workshop[]) => {
          const index = draft?.findIndex((workshop) => {
            return workshop.id === workshopId;
          });
          // eslint-disable-next-line no-param-reassign
          if (index !== undefined && index !== -1 && draft) draft[index].user_data = {is_favorite: false, last_viewed};
        });
      });
    },
  });
};

export const useAddWorkshopUsers = (workshopId: number) => {
  return useMutation({
    mutationFn: (data: {userIds: number[]; access: ToolboxObject.WorkshopUserAccess}) => {
      return addWorkshopUsers(workshopId, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['workshops']);
    },
  });
};

export const useWorkshopAvailableUsers = (workshopId: number, options: UseQueryOptions<ToolboxObject.User[]> = {}) => {
  return useQuery<ToolboxObject.User[]>(
    ['workshops', workshopId, 'users'],
    () => {
      return getWorkshopAvailableUsers(workshopId);
    },
    options
  );
};

export const useLikeRecipe = (recipeId: number, isLiked: boolean) => {
  return useMutation({
    mutationFn: () => {
      return toggleLikeRecipe(recipeId, isLiked);
    },
    onSuccess: (data) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {is_favorite, last_viewed} = data;
      return queryClient.setQueryData(['recipes'], (oldRecipes: ToolboxObject.Workshop[] | undefined) => {
        return produce(oldRecipes, (draft: ToolboxObject.Workshop[]) => {
          const index = draft?.findIndex((recipe) => {
            return recipe.id === recipeId;
          });
          // eslint-disable-next-line no-param-reassign
          if (index !== undefined && index !== -1 && draft) draft[index].user_data = {is_favorite, last_viewed};
        });
      });
    },
  });
};

export const useViewRecipe = (recipeId: number) => {
  return useMutation({
    mutationFn: () => {
      return viewRecipe(recipeId);
    },
    onSuccess: (data) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {is_favorite, last_viewed} = data;
      return queryClient.setQueryData(['recipes'], (oldRecipes: ToolboxObject.Workshop[] | undefined) => {
        return produce(oldRecipes, (draft: ToolboxObject.Workshop[]) => {
          const index = draft?.findIndex((recipe) => {
            return recipe.id === recipeId;
          });
          // eslint-disable-next-line no-param-reassign
          if (index !== undefined && index !== -1 && draft) draft[index].user_data = {is_favorite, last_viewed};
        });
      });
    },
  });
};

export const useSaveWorkshop = (isRecipe?: boolean) => {
  return useMutation({
    mutationFn: (data: {
      id: number;
      sessions: ToolboxObject.WorkshopSession[];
      title: string;
      description: string;
      requirements: string[];
      benefits: string[];
      category?: ToolboxObject.WorkshopCategory | null;
      teaser?: string | null;
      checklist: ToolboxObject.ChecklistTask[];
    }) => {
      const saveFunction = isRecipe ? saveRecipe : saveWorkshop;
      return saveFunction(data.title, data.sessions, data.id, data.description, data.requirements, data.benefits, data.category, data.teaser, data.checklist);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([isRecipe ? 'recipes' : 'workshops']);
    },
  });
};

export const useMiroBoardFromWorkshop = (workshopId: number) => {
  return useMutation({
    mutationFn: () => {
      return miroBoardFromWorkshop(workshopId);
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(['workshop', workshopId]);
      await queryClient.invalidateQueries(['workshops']);
      window.open(data, '_blank', 'noreferrer');
    },
  });
};

export const useDisconnectMiro = () => {
  return useMutation({
    mutationFn: () => {
      return disconnectMiro();
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['me']);
    },
  });
};

export const useLinkMiroBoardToWorkshop = (workshopId: number) => {
  return useMutation({
    mutationFn: (href: string | null) => {
      return linkMiroBoardToWorkshop(workshopId, href);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['workshop', workshopId]);
      await queryClient.invalidateQueries(['workshops']);
    },
  });
};

export const useCreateWorkshop = (isRecipe?: boolean) => {
  return useMutation({
    mutationFn: (data: {
      sessions: ToolboxObject.WorkshopSession[];
      title: string;
      description: string;
      requirements: string[];
      benefits: string[];
      category?: ToolboxObject.WorkshopCategory | null;
      teaser?: string | null;
      checklist: ToolboxObject.ChecklistTask[];
    }) => {
      const createFunction = isRecipe ? createRecipe : createWorkshop;
      return createFunction(
        data.title,
        data.sessions,
        data.description,
        data.requirements,
        data.benefits,
        isRecipe ? data.category ?? null : null,
        isRecipe ? data.teaser ?? null : null,
        data.checklist
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([isRecipe ? 'recipes' : 'workshops']);
    },
  });
};

export const useDuplicateWorkshop = (workshopId: number) => {
  return useMutation({
    mutationFn: (data: {title: string}) => {
      return duplicateWorkshop(workshopId, data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['workshops']);
    },
  });
};

export const useExportAsRecipe = (workshopId: number) => {
  return useMutation({
    mutationFn: (data: {category: ToolboxObject.WorkshopCategory | null; teaser: string | null}) => {
      return exportAsRecipe(workshopId, data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['workshops']);
    },
  });
};

export const useDeleteWorkshop = (isRecipe?: boolean) => {
  return useMutation({
    mutationFn: (data: {id: number}) => {
      const deleteFunction = isRecipe ? deleteRecipe : deleteWorkshop;
      return deleteFunction(data.id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([isRecipe ? 'recipes' : 'workshops']);
    },
  });
};
