import {request} from '..';

export const login = (data: {email: string; password: string}) => {
  return request({
    url: '/users/login',
    method: 'POST',
    data,
  });
};

export const forgotPassword = (data: {email: string}) => {
  return request({
    url: '/users/password/reset',
    method: 'PUT',
    data,
  });
};

export const register = (data: {email: string; password: string; token: string}) => {
  return request({
    url: '/users/register',
    method: 'PUT',
    data,
  });
};

export const invite = (data: {email: string; firstName: string; lastName: string; role: string}) => {
  return request({
    url: '/users/invite',
    method: 'POST',
    data,
  });
};

export const logout = () => {
  return request({
    url: '/users/logout',
    method: 'GET',
  });
};

export const getSelf = () => {
  return request<ToolboxObject.User>({
    url: '/users/me',
    method: 'GET',
  });
};
