import React from 'react';
import {useDocumentTitle} from 'hooks';
import Xarrow from 'react-xarrows';

import {useWorkshops} from '../../services/query/modules';
import {WorkshopLibraryTile} from '../../components/LibraryTile';
import {LoadingComponent, LocalizedText} from 'components';

interface LibraryPageProps {
  defaultTab?: string;
}

export const WorkshopLibraryPage: React.FC<LibraryPageProps> = () => {
  useDocumentTitle('library');

  const workshops = useWorkshops();
  return (
    <div>
      <div className="flex flex-col px-4 sm:px-6 lg:px-24 py-12 top-16 gap-4 max-w-[1600px] m-auto">
        <p className="text-2xl font-extrabold text-gray-900">
          <LocalizedText id="pages.workshop.pageTitle" />
        </p>
        <LoadingComponent query={workshops}>
          {() => {
            if (workshops.data.length) {
              return (
                <div className="grid gap-6 md:grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 mb-8">
                  {workshops.data.map((workshop: ToolboxObject.Workshop) => {
                    return <WorkshopLibraryTile workshop={workshop} key={workshop.id} />;
                  })}
                </div>
              );
            }
            return (
              <>
                <div className="hidden md:block">
                  <div id="create-workshop-tip" className="w-full flex flex-col text-center justify-center items-center gap-2">
                    <span className="text-xl text-gray-900">
                      <LocalizedText id="pages.workshop.emptyHere" />
                    </span>
                    <span>
                      <LocalizedText id="pages.workshop.startCreateUp" />
                    </span>
                  </div>
                  <Xarrow start="create-workshop-tip" end="create-workshop-button" startAnchor="top" endAnchor="bottom" color="#7DD3FC" />
                </div>
                <div className="md:hidden w-full flex flex-col text-start gap-4">
                  <span className="text-xl text-gray-900">
                    <LocalizedText id="pages.workshop.emptyHere" />
                  </span>
                  <span>
                    <LocalizedText id="pages.workshop.startCreateTopLeft" />
                  </span>
                </div>
              </>
            );
          }}
        </LoadingComponent>
      </div>
    </div>
  );
};
