import {QueryClient} from '@tanstack/query-core';
import {createSyncStoragePersister} from '@tanstack/query-sync-storage-persister';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
});
