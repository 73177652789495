import {useMutation, useQuery} from '@tanstack/react-query';
import {getNotifications, updateNotifications} from 'services/axios/modules';
import {queryClient} from '..';

export const useNotifications = () => {
  return useQuery(
    ['notifications'],
    () => {
      return getNotifications();
    },
    {
      cacheTime: 0,
      refetchInterval: 5000,
    }
  );
};

export const useUpdateNotifications = () => {
  return useMutation({
    mutationFn: (data: Partial<Omit<ToolboxObject.Notification, 'id'>> & {notificationIds: number[]}) => {
      return updateNotifications(data);
    },
    onSuccess: (result) => {
      queryClient.setQueryData(['notifications'], result);
    },
  });
};
