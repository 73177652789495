import {MethodStatus} from 'types';
import {request} from '..';

export const getMethods = async (statuses: MethodStatus[] = [MethodStatus.PUBLISHED]) => {
  return request<ToolboxObject.Method[]>({
    url: '/methods',
    method: 'GET',
    params: {
      statuses,
    },
  });
};

export const getMethod = async (id: number) => {
  return request<ToolboxObject.MethodDetails>({
    url: `/methods/${id}`,
    method: 'GET',
  });
};

export const createMethod = async (data: FormData) => {
  return request({
    url: '/methods',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
};

export const deleteMethod = async (id: number) => {
  return request<ToolboxObject.Method[]>({
    url: `/methods/${id}`,
    method: 'DELETE',
  });
};

export const updateMethod = async (id: number, data: FormData) => {
  return request({
    url: `/methods/${id}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
};

export const toggleLikeMethod = async (methodId: number, isLiked: boolean) => {
  return request({
    url: `/methods/${methodId}/${isLiked ? 'unfavorite' : 'favorite'}`,
    method: 'PUT',
  });
};

export const viewMethod = async (methodId: number) => {
  return request({
    url: `/methods/${methodId}/view`,
    method: 'PUT',
  });
};
