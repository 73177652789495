import {DragDropContext, DropResult} from '@hello-pangea/dnd';
import React from 'react';
import _isEqual from 'lodash/isEqual';
import dayjs from 'dayjs';
import {ContentSave} from 'assets';
import {useLinkMiroBoardToWorkshop, useMethods, useMiroBoardFromWorkshop, useSelf} from 'services/query/modules';
import classNames from 'classnames';
import {Button, FakeProgressLoader, InlineEdit, WarningModal} from 'components';
import ReactRouterPrompt from 'react-router-prompt';
import {translateMethod, useDocumentTitle, useLocale, useSaveWorkshopActions, useSocketConnection} from 'hooks';
import {UserRole} from 'types';
import {
  AddMiroButton,
  AddMiroModal,
  WorkshopActions,
  MethodLibrary,
  OpenMiroButton,
  SessionTabs,
  WorkshopCreator,
  WorkshopForm,
  WorkshopOverview,
} from './components';
import {getEmptySession, getWorkshopState, WorkshopModificationState} from './helpers';
import {useSessionTabs, useWorkshopCreator, useWorkshopForm} from './hooks';
import styles from './WorkshopsPage.module.scss';
import {toast} from 'react-toastify';
import {useIntl} from 'react-intl';
import {DropType, handleWorkshopMethodDrag, moveDraggedItem, MoveDraggedItemProps} from 'utils';
import {useLiveWorkshopUpdates} from './hooks/useLiveWorkshopUpdates';
import {Socket} from 'socket.io-client';

const creatorDroppableId = 'creator';
const defaultTitle = 'New Workshop';

interface WorkshopTemplateProps {
  workshop?: Partial<ToolboxObject.Workshop>;
  isRecipe?: boolean;
}

export const WorkshopTemplate: React.FC<WorkshopTemplateProps> = ({workshop = undefined, isRecipe = false}) => {
  useDocumentTitle('root');
  const locale = useLocale();
  const intl = useIntl();
  const workshopId = workshop?.id;

  const initialWorkshop = {
    sessions: (workshop?.sessions || [getEmptySession()]).map((s) => ({
      ...s,
      editable_methods: s.editable_methods.map((method) => ({
        ...method,
        original_method: translateMethod(locale, method.original_method),
        expanded: undefined,
      })),
    })),
    title: workshop?.title || defaultTitle,
    description: workshop?.description || '',
    requirements: workshop?.requirements || [],
    benefits: workshop?.benefits || [],
    checklist: workshop?.checklist || [],
    category: workshop?.category || null,
    teaser: workshop?.teaser || null,
  };

  const [isManualChange, setIsManualChange] = React.useState(false);

  const [miroModalOpen, setMiroModalOpen] = React.useState(false);
  const [sessions, setSessions] = React.useState<ToolboxObject.WorkshopSession[]>(initialWorkshop.sessions);
  const [title, setTitle] = React.useState(initialWorkshop.title);
  const [description, setDescription] = React.useState(initialWorkshop.description);
  const [requirements, setRequirements] = React.useState(initialWorkshop.requirements);
  const [benefits, setBenefits] = React.useState(initialWorkshop.benefits);
  const [checklist, setChecklist] = React.useState<Array<ToolboxObject.ChecklistTask>>(initialWorkshop.checklist);
  const [category, setCategory] = React.useState(initialWorkshop.category ?? null);
  const [teaser, setTeaser] = React.useState(initialWorkshop.teaser ?? null);
  const [activeSession, setActiveSession] = React.useState(0);
  const [originWorkshopData, setOriginWorkshopData] = React.useState<ToolboxObject.WorkshopEditableState>(initialWorkshop);

  const setWorkshopData = (state: Partial<ToolboxObject.WorkshopEditableState>, isManualChange = true) => {
    setSessions(state.sessions || sessions);
    setTitle(state.title || title);
    setDescription(state.description || description || '');
    setRequirements(state.requirements || requirements || []);
    setBenefits(state.benefits || benefits || []);
    setChecklist(state.checklist || checklist);
    setCategory(state.category || category || null);
    setTeaser(state.teaser || teaser || null);

    setIsManualChange(isManualChange);
  };
  const currentWorkshopData = {title, sessions, description, requirements, benefits, category, teaser, checklist};
  const originalWorkshopState = React.useMemo(() => getWorkshopState(originWorkshopData), [originWorkshopData]);
  const currentWorkshopState = React.useMemo(() => getWorkshopState(currentWorkshopData), [JSON.stringify(currentWorkshopData)]);

  const methods = useMethods();
  const self = useSelf();

  const isReadyOnly = !workshop?.user_data?.is_owner && workshop?.user_data?.access === 'read';
  const showMiro = !workshop?.is_recipe && self.data?.role && [UserRole.ADMIN.toString(), UserRole.MIRO.toString()].includes(self.data.role);

  const handlers = React.useMemo(
    () => [
      {
        event: 'state-sync',
        handleFn: ({state, isSaved}: {isSaved: Boolean; state: ToolboxObject.WorkshopEditableState}, socket: Socket) => {
          if (state) {
            setWorkshopData(state, false);
            if (isSaved) {
              setOriginWorkshopData(state);
            }
          } else {
            socket?.emit('state-sync', {state: currentWorkshopState});
          }
        },
      },
    ],
    [currentWorkshopState]
  );
  const socket = useSocketConnection({
    query: {workshopId},
    handlers,
    enabled: !!workshopId,
    namespace: 'workshops',
    handleConnect: (socket) => {
      socket?.emit('state-sync', {});
    },
  });
  useLiveWorkshopUpdates({socket, enabled: !isReadyOnly && isManualChange, currentWorkshopState});

  React.useEffect(() => {
    setSessions(initialWorkshop.sessions);
    setActiveSession(0);
  }, [locale]);

  const {mutate: createMiroBoard, isLoading: isCreatingMiroBoard} = useMiroBoardFromWorkshop(workshop?.id ?? -1);
  const {mutate: linkMiroBoard} = useLinkMiroBoardToWorkshop(workshop?.id ?? -1);

  const {handleCreate, handleSave, isLoading: isMutationLoading} = useSaveWorkshopActions({workshopId, isRecipe});

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      const dropTypeMap: Record<string, Omit<MoveDraggedItemProps, 'dropResult'>> = {
        [DropType.WorkshopBenefit]: {setValue: (benefits) => setWorkshopData({benefits}), value: benefits},
        [DropType.WorkshopRequirement]: {setValue: (requirements) => setWorkshopData({requirements}), value: requirements},
        [DropType.WorkshopChecklist]: {setValue: (checklist) => setWorkshopData({checklist}), value: checklist},
        [DropType.WorkshopSteps]: {
          setValue: (sessions) => setWorkshopData({sessions}),
          value: sessions,
          getInnerPath: (destination) => {
            const editableMethodIndex = +destination.droppableId.slice(destination.droppableId.lastIndexOf('-') + 1);
            return `[${activeSession}].editable_methods[${editableMethodIndex}].steps`;
          },
        },
      };

      const baseDropType = result.type.split('-')[0];
      if (dropTypeMap[baseDropType]) {
        moveDraggedItem({
          dropResult: result,
          ...dropTypeMap[baseDropType],
        });
        return;
      }

      if (result.type === DropType.MethodLibrary) {
        handleWorkshopMethodDrag({
          result,
          sessions,
          setSessions: (sessions) => setWorkshopData({sessions}),
          self: self.data,
          locale,
          methods: methods.data,
          activeSession,
          creatorDroppableId,
        });
      }
    },
    [sessions, activeSession, methods, self]
  );

  const editableMethods = React.useMemo(() => {
    if (activeSession === -1) {
      return null;
    }
    return sessions[activeSession].editable_methods;
  }, [sessions, activeSession]);

  const startDate = React.useMemo(() => {
    if (activeSession === -1) {
      return null;
    }
    return dayjs(sessions[activeSession].start);
  }, [sessions, activeSession]);

  const modificationState: WorkshopModificationState = {sessions, setSessions: (sessions) => setWorkshopData({sessions}), activeSession, setActiveSession};
  const sessionTabsData = useSessionTabs(modificationState);
  const workshopFormData = useWorkshopForm(modificationState);
  const workshopCreatorDate = useWorkshopCreator(modificationState);

  const hasUnsavedChanges = React.useMemo(() => {
    return !isMutationLoading && !!originWorkshopData && !_isEqual(originalWorkshopState, currentWorkshopState);
  }, [sessions, title, description, originWorkshopData, isMutationLoading, requirements, benefits, category, teaser, checklist]);

  if (isCreatingMiroBoard) {
    return (
      <div className="w-full flex-col justify-center items-center text-center pt-14 gap-8">
        <p className="font-bold text-xl pb-8">Creating Miro Board</p>
        <FakeProgressLoader loading={isCreatingMiroBoard} />
      </div>
    );
  }
  return (
    <div>
      <ReactRouterPrompt when={hasUnsavedChanges}>
        {({onConfirm, onCancel}) => {
          return (
            <WarningModal
              isOpen
              handleClose={onCancel}
              handleConfirm={onConfirm}
              titleKey="modals.unsavedWorkshop.title"
              confirmButtonTitleKey="modals.unsavedWorkshop.confirmTitle"
              descriptionKey="modals.unsavedWorkshop.description"
            />
          );
        }}
      </ReactRouterPrompt>
      {
        // TODO: temporary disclaimer
        workshop?.is_recipe && (
          <div className="absolute w-full bg-deletered h-8 text-center text-white items-center justify-center flex z-20">You are editing a public recipe</div>
        )
      }
      <div className="flex flex-col md:flex-row items-stretch gap-4 md:gap-0 md:h-full w-full">
        <DragDropContext onDragEnd={onDragEnd}>
          <div
            className={classNames(styles.noScrollbar, 'flex flex-col gap-4 grow basis-[60%] bg-white p-6 sm:p-12 md:h-[calc(100vh-64px)] md:overflow-y-auto')}
          >
            <div className="flex gap-2 justify-between flex-wrap sm:flex-nowrap items-center">
              <InlineEdit
                className="text-2xl font-extrabold text-gray-900 w-full"
                value={title}
                setValue={(title) => setWorkshopData({title})}
                disabled={isReadyOnly}
              />

              <div className="flex gap-2 flex-wrap sm:flex-nowrap">
                {showMiro &&
                  !isReadyOnly &&
                  (workshop?.miro_href ? (
                    <OpenMiroButton linkMiroBoard={linkMiroBoard} href={workshop?.miro_href ?? ''} />
                  ) : (
                    <AddMiroButton
                      onClick={() => {
                        if (hasUnsavedChanges) {
                          toast.warn(intl.formatMessage({id: 'toast.workshop.addMiro.warning'}), {autoClose: 3000});
                        } else {
                          setMiroModalOpen(true);
                        }
                      }}
                    />
                  ))}
                {self.data?.role && (
                  <WorkshopActions hasUnsavedChanges={hasUnsavedChanges} userRole={self.data?.role} workshopId={workshopId} isReadyOnly={isReadyOnly} />
                )}
                {handleSave && !isReadyOnly && (
                  <Button
                    onClick={() => {
                      const data = {
                        title,
                        sessions,
                        description: description ?? '',
                        requirements: requirements ?? [],
                        benefits: benefits ?? [],
                        checklist: checklist ?? [],
                        category: category ?? null,
                        teaser: teaser ?? null,
                      };
                      if (workshopId) {
                        handleSave(data);
                        socket?.emit('state-sync', {state: currentWorkshopState, isSaved: true});
                      } else {
                        handleCreate(data);
                      }
                      setOriginWorkshopData(currentWorkshopData);
                    }}
                    className="px-0 w-[44px] h-[44px]"
                  >
                    <ContentSave fill="white" />
                  </Button>
                )}
              </div>
            </div>
            <SessionTabs {...sessionTabsData} disabled={isReadyOnly} />
            {editableMethods !== null ? (
              <>
                <WorkshopForm {...workshopFormData} editableMethods={editableMethods} startDate={startDate} disabled={isReadyOnly} />
                <WorkshopCreator
                  {...workshopCreatorDate}
                  editableMethods={editableMethods}
                  droppableId={creatorDroppableId}
                  startDate={startDate}
                  disabled={isReadyOnly}
                  workshopId={workshopId}
                />
              </>
            ) : (
              <WorkshopOverview
                description={description ?? ''}
                setDescription={(description) => setWorkshopData({description})}
                benefits={benefits ?? []}
                setBenefits={(benefits) => setWorkshopData({benefits})}
                requirements={requirements ?? []}
                setRequirements={(requirements) => setWorkshopData({requirements})}
                checklist={checklist ?? []}
                setChecklist={(checklist) => setWorkshopData({checklist})}
                setCategory={workshop?.is_recipe ? (category) => setWorkshopData({category}) : undefined}
                category={category}
                setTeaser={workshop?.is_recipe ? (teaser) => setWorkshopData({teaser}) : undefined}
                teaser={teaser}
                disabled={isReadyOnly}
              />
            )}
          </div>
          <MethodLibrary isLoading={methods.isLoading} methods={methods.data || []} disabled={isReadyOnly} />
        </DragDropContext>
      </div>
      <AddMiroModal
        isOpen={miroModalOpen}
        onClose={() => setMiroModalOpen(false)}
        hasMiro={!!self.data?.miroAccess}
        createMiroBoard={createMiroBoard}
        linkMiroBoard={linkMiroBoard}
      />
    </div>
  );
};
