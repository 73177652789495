import produce from 'immer';
import {request} from '..';

const formatWorkshop = (workshop: ToolboxObject.Workshop) => {
  workshop.sessions.sort((a: ToolboxObject.WorkshopSession, b: ToolboxObject.WorkshopSession) => {
    return a.order - b.order;
  });
  return workshop.sessions.forEach((session: ToolboxObject.WorkshopSession) => {
    session.editable_methods.sort((a: ToolboxObject.EditableMethod, b: ToolboxObject.EditableMethod) => {
      return a.order - b.order;
    });
  });
};

export const getWorkshops = async (params?: ToolboxObject.FindWorkshopParams, isRecipes?: boolean) => {
  const workshops = await request({
    url: isRecipes ? '/recipes' : '/workshops',
    method: 'GET',
    params,
  });
  if (workshops) {
    return produce(workshops, (draft: ToolboxObject.Workshop[]) => {
      draft.forEach((workshop: ToolboxObject.Workshop) => {
        return formatWorkshop(workshop);
      });
    });
  }
  return workshops;
};

export const getRecipes = async (params?: ToolboxObject.FindWorkshopParams) => {
  return getWorkshops(params, true);
};

export const getWorkshop = async (id: number, isRecipe?: boolean) => {
  const workshop = await request({
    url: `/${isRecipe ? 'recipes' : 'workshops'}/${id}`,
    method: 'GET',
  });
  if (workshop) {
    return produce(workshop, (draft: ToolboxObject.Workshop) => {
      return formatWorkshop(draft);
    });
  }
  return workshop;
};

export const getRecipe = async (id: number) => {
  return getWorkshop(id, true);
};

export const viewWorkshop = async (workshopId: number) => {
  return request({
    url: `/workshops/${workshopId}/view`,
    method: 'PUT',
  });
};

export const addWorkshopUsers = async (workshopId: number, data: {userIds: number[]; access: ToolboxObject.WorkshopUserAccess}): Promise<void> => {
  return request({
    url: `/workshops/${workshopId}/users`,
    method: 'POST',
    data,
  });
};

export const getWorkshopAvailableUsers = async (workshopId: number): Promise<ToolboxObject.User[]> => {
  return request({
    url: `/workshops/${workshopId}/users`,
    method: 'GET',
  });
};

export const toggleLikeRecipe = async (recipeId: number, isLiked: boolean) => {
  return request({
    url: `/recipes/${recipeId}/${isLiked ? 'unfavorite' : 'favorite'}`,
    method: 'PUT',
  });
};

export const viewRecipe = async (recipeId: number) => {
  return request({
    url: `/recipes/${recipeId}/view`,
    method: 'PUT',
  });
};

export const saveWorkshop = async (
  title: string,
  sessions: ToolboxObject.WorkshopSession[],
  id: number,
  description: string,
  requirements: string[],
  benefits: string[],
  category?: ToolboxObject.WorkshopCategory | null,
  teaser?: string | null,
  checklist?: ToolboxObject.ChecklistTask[],
  isRecipe?: boolean
) => {
  return request({
    url: `/${isRecipe ? 'recipes' : 'workshops'}/${id}`,
    method: 'PUT',
    data: {title, sessions, description, requirements, benefits, categoryId: category?.id ?? null, checklist, teaser},
  });
};

export const saveRecipe = async (
  title: string,
  sessions: ToolboxObject.WorkshopSession[],
  id: number,
  description: string,
  requirements: string[],
  benefits: string[],
  category?: ToolboxObject.WorkshopCategory | null,
  teaser?: string | null,
  checklist?: ToolboxObject.ChecklistTask[]
) => {
  return saveWorkshop(title, sessions, id, description, requirements, benefits, category, teaser, checklist, true);
};

export const miroBoardFromWorkshop = async (id: number) => {
  return request({
    url: `/workshops/${id}/miro`,
    method: 'POST',
  });
};

export const linkMiroBoardToWorkshop = async (id: number, href: string | null) => {
  return request({
    url: `/workshops/${id}/miro`,
    method: 'PUT',
    data: {href},
  });
};

export const disconnectMiro = () => {
  return request({
    url: '/miro',
    method: 'DELETE',
  });
};

export const createWorkshop = async (
  title: string,
  sessions: ToolboxObject.WorkshopSession[],
  description: string,
  requirements: string[],
  benefits: string[],
  category: ToolboxObject.WorkshopCategory | null,
  teaser: string | null,
  checklist: ToolboxObject.ChecklistTask[],
  isRecipe?: boolean
) => {
  return request({
    url: isRecipe ? '/recipes' : `/workshops`,
    method: 'POST',
    data: {title, sessions, description, requirements, benefits, categoryId: category?.id ?? null, checklist, teaser},
  });
};

export const createRecipe = async (
  title: string,
  sessions: ToolboxObject.WorkshopSession[],
  description: string,
  requirements: string[],
  benefits: string[],
  category: ToolboxObject.WorkshopCategory | null,
  teaser: string | null,
  checklist: ToolboxObject.ChecklistTask[]
) => {
  return createWorkshop(title, sessions, description, requirements, benefits, category, teaser, checklist, true);
};

export const exportAsRecipe = async (workshopId: number, data: {category: ToolboxObject.WorkshopCategory | null; teaser: string | null}) => {
  return request({
    url: `/workshops/${workshopId}/create-recipe`,
    method: 'POST',
    data: {categoryId: data.category?.id ?? null, teaser: data.teaser},
  });
};

export const duplicateWorkshop = async (workshopId: number, data: {title: string}) => {
  return request({
    url: `/workshops/${workshopId}/duplicate`,
    method: 'POST',
    data,
  });
};

export const deleteWorkshop = async (workshopId: number, isRecipe?: boolean) => {
  return request({
    url: `/${isRecipe ? 'recipes' : 'workshops'}/${workshopId}`,
    method: 'DELETE',
  });
};
export const deleteRecipe = async (workshopId: number) => {
  return deleteWorkshop(workshopId, true);
};

export const getMethodSubcategories = async () => {
  return request<ToolboxObject.Subcategory[]>({
    url: `/categories/subcategories`,
    method: 'GET',
  });
};

export const getRecipeCategories = async () => {
  return request<ToolboxObject.WorkshopCategory[]>({
    url: `/recipes/categories`,
    method: 'GET',
  });
};
