import axios, {AxiosRequestConfig} from 'axios';

const client = (() => {
  return axios.create({
    baseURL: process.env.REACT_APP_TOOLBOX_HOST,
    withCredentials: true,
  });
})();

export const request = async <T = any>(options: AxiosRequestConfig<any>) => {
  const onSuccess = (response: {data: T}) => {
    return response.data;
  };
  const onError = (error: {response: any}) => {
    const clearStorageErrors = [401, 429, 403];
    if (error.response?.status && clearStorageErrors.includes(error.response.status)) {
      localStorage.clear();
    }
    return Promise.reject(error.response);
  };
  return client(options).then(onSuccess).catch(onError);
};
