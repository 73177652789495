import {Menu, Transition} from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';

interface MenuComponentProps {
  triggerElement: React.ReactNode;
  items: {title: string; onClick?(): void; element?: React.ReactNode}[];
  className?: string;
  itemsClassName?: string;
}

const MenuComponent: React.FC<MenuComponentProps> = ({triggerElement, items, className = '', itemsClassName}) => {
  return (
    <Menu as="div" className={classNames('relative cursor-pointer', className)}>
      <Menu.Button className="flex" as="div">
        {triggerElement}
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            'absolute z-10 mt-2 right-0 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            itemsClassName
          )}
        >
          {items.map((item) => {
            return (
              <Menu.Item key={item.title}>
                {/* @ts-ignore */}
                {({active}) => {
                  if (item.element) {
                    return item.element;
                  }
                  return (
                    <button
                      type="button"
                      className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-start px-4 py-2 text-sm text-gray-700')}
                      onClick={item.onClick}
                    >
                      {item.title}
                    </button>
                  );
                }}
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export {MenuComponent as Menu};
