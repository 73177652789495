import {MutationFunction, useMutation, useQuery} from '@tanstack/react-query';
import {
  createTranslation,
  deleteTranslation,
  getAITranslation,
  getTranslations,
  MethodDetailsTranslationFn,
  updateTranslation,
  upsertMethodInfoTranslation,
  upsertMethodMaterialTranslation,
  upsertMethodStepTranslation,
  upsertMethodTranslation,
} from 'services/axios/modules';
import {queryClient} from '..';

export const useTranlsations = () => {
  return useQuery(['translations'], () => {
    return getTranslations();
  });
};

export const useAITranlsation = () => {
  return useMutation({
    mutationFn: (data: {message: string; lang: string}) => {
      return getAITranslation(data);
    },
  });
};

export const useUpdateTranlsation = () => {
  return useMutation({
    mutationFn: (data: ToolboxObject.Translation) => {
      const {id, ...rest} = data;
      return updateTranslation(id, rest);
    },
    onSuccess: async (updatedTranslation: ToolboxObject.Translation) => {
      queryClient.setQueryData(['translations'], (translations: ToolboxObject.Translation[] = []) => {
        return translations.map((t) => (t.id === updatedTranslation.id ? updatedTranslation : t));
      });
      queryClient.invalidateQueries(['methods']);
    },
  });
};

export const useCreateTranlsation = () => {
  return useMutation({
    mutationFn: (data: Omit<ToolboxObject.Translation, 'id'>) => {
      return createTranslation(data);
    },
    onSuccess: async (createdTranslation: ToolboxObject.Translation) => {
      queryClient.setQueryData(['translations'], (translations: ToolboxObject.Translation[] = []) => {
        return [createdTranslation, ...translations];
      });
      queryClient.invalidateQueries(['methods']);
    },
  });
};

export const useDeleteTranlsation = () => {
  return useMutation({
    mutationFn: (id: number) => {
      return deleteTranslation(id);
    },
    onSuccess: async (deletedId: number) => {
      queryClient.setQueryData(['translations'], (translations: ToolboxObject.Translation[] = []) => {
        return translations.filter((t) => t.id !== deletedId);
      });
      queryClient.invalidateQueries(['methods']);
    },
  });
};

export type UpsertMethodItemMutationFunction<T = number> = MutationFunction<
  ToolboxObject.MethodTranslationDetails,
  {itemId: T; translation: ToolboxObject.Translation}
>;

export const useUpsertMethodItemTranlsation = <T = number>(methodId: number, createFn: MethodDetailsTranslationFn<T>) => {
  const mutationFn: UpsertMethodItemMutationFunction<T> = async ({itemId, translation}) => {
    return createFn(methodId, itemId, translation);
  };
  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries(['methods']);
    },
  });
};

export const useUpsertMethodTranlsation = (methodId: number) => {
  return useUpsertMethodItemTranlsation(methodId, upsertMethodTranslation);
};

export const useUpsertMethodInfoTranlsation = (methodId: number) => {
  return useUpsertMethodItemTranlsation(methodId, upsertMethodInfoTranslation);
};

export const useUpsertMethodMaterialTranlsation = (methodId: number) => {
  return useUpsertMethodItemTranlsation(methodId, upsertMethodMaterialTranslation);
};

export const useUpsertMethodStepTranlsation = (methodId: number) => {
  return useUpsertMethodItemTranlsation(methodId, upsertMethodStepTranslation);
};
