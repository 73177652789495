import {request} from '..';

export const getTranslations = () => {
  return request<ToolboxObject.Translation[]>({
    url: '/translations',
    method: 'GET',
  });
};

export const getAITranslation = (data: {message: string; lang: string}) => {
  return request<string[]>({
    url: '/translations/help',
    method: 'POST',
    data,
  });
};

export const updateTranslation = (id: number, data: Omit<ToolboxObject.Translation, 'id'>) => {
  return request<ToolboxObject.Translation>({
    url: `/translations/${id}`,
    method: 'PUT',
    data,
  });
};

export const createTranslation = (data: Omit<ToolboxObject.Translation, 'id'>) => {
  return request<ToolboxObject.Translation>({
    url: `/translations`,
    method: 'POST',
    data,
  });
};

export const deleteTranslation = (id: number) => {
  return request<number>({
    url: `/translations/${id}`,
    method: 'DELETE',
  });
};

export type MethodDetailsTranslationFn<T = number> = (
  methodId: number,
  itemId: T,
  data: ToolboxObject.Translation
) => Promise<ToolboxObject.MethodTranslationDetails>;

export const upsertMethodTranslation: MethodDetailsTranslationFn<string> = (methodId, itemId, data) => {
  return request<ToolboxObject.MethodTranslation>({
    url: `/translations/methods/${methodId}`,
    method: 'POST',
    data: {
      ...data,
      method_key: itemId,
    },
  });
};

export const upsertMethodInfoTranslation: MethodDetailsTranslationFn = (methodId, itemId, data) => {
  return request({
    url: `/translations/methods/${methodId}/infos`,
    method: 'POST',
    data: {
      ...data,
      method_info_id: itemId,
    },
  });
};

export const upsertMethodMaterialTranslation: MethodDetailsTranslationFn = (methodId, itemId, data) => {
  return request<ToolboxObject.MethodMaterialTranslation>({
    url: `/translations/methods/${methodId}/materials`,
    method: 'POST',
    data: {
      ...data,
      method_material_id: itemId,
    },
  });
};

export const upsertMethodStepTranslation: MethodDetailsTranslationFn = (methodId, itemId, data) => {
  return request<ToolboxObject.MethodStepTranslation>({
    url: `/translations/methods/${methodId}/steps`,
    method: 'POST',
    data: {
      ...data,
      method_step_id: itemId,
    },
  });
};
