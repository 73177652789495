import React, {useMemo} from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {Briefcase, Clock, DifficultyDots, Pencil, People} from 'assets';
import {Button} from 'components';

import {UserRole} from 'types';
import styles from './Header.module.scss';
import {useSelf} from '../../../../services/query/modules';
import {useLocalizedNavigation} from 'hooks';

interface HeaderProps {
  recipe: ToolboxObject.Workshop;
  duration: number;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const Header: React.FC<HeaderProps> = ({recipe, duration}) => {
  const navigate = useLocalizedNavigation();
  const {data: self} = useSelf();
  const minPeople = useMemo(() => {
    return recipe.sessions
      .flatMap((session) => session.editable_methods)
      .find((method) => {
        return method.min_people && method.min_people > 1;
      })
      ? 'Group'
      : '1 : 1';
  }, [recipe]);
  const difficulty = useMemo(() => {
    const methodsDifficulty = recipe.sessions
      .flatMap((session) => session.editable_methods)
      .map((method) => {
        if (method.difficulty) {
          return method.difficulty;
        }
        return 0;
      });
    return Math.max(...methodsDifficulty);
  }, [recipe]);

  const createWorkshop = () => {
    navigate('/editor/new', {
      state: {
        recipe_sessions: recipe.sessions,
        recipe_description: recipe.description,
        recipe_benefits: recipe.benefits,
        recipe_requirements: recipe.requirements,
      },
    });
  };

  const editWorkshop = () => {
    navigate(`/editor/${recipe.id}`);
  };

  const editRecipe = () => {
    navigate(`/editor/recipe/${recipe.id}`);
  };

  return (
    <header className={classNames(styles.header, 'flex gap-4 flex-wrap justify-between w-full pr-10')}>
      <div className={styles.info}>
        <h2 className={styles.heading}>{recipe.title}</h2>
        <div className="flex items-center gap-4 flex-wrap">
          <div className="flex items-center">
            <Clock />
            {duration && <span className={styles.text}>{(duration / (1000 * 60)).toFixed(0)} min</span>}
          </div>
          <div className="flex items-center">
            <People />
            <span className={styles.text}>{minPeople}</span>
          </div>
          <div className="flex items-center">
            <DifficultyDots />
            <span className={styles.text}>{(difficulty === 1 && 'Easy') || (difficulty === 2 && 'Medium') || (difficulty === 3 && 'Hard')}</span>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        {recipe.is_recipe ? (
          <>
            {self?.role === UserRole.ADMIN && (
              <Button onClick={editRecipe}>
                <Pencil />
                Edit Recipe
              </Button>
            )}
            <Button onClick={createWorkshop}>
              <Briefcase />
              Create Workshop
            </Button>
          </>
        ) : (
          <Button onClick={editWorkshop}>
            <Pencil />
            Edit Workshop
          </Button>
        )}
      </div>
    </header>
  );
};
