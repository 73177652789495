import React, {useMemo} from 'react';
import dayjs from 'dayjs';
import {Modal} from '../../Modal';
import {Footer, Title} from '../components';
import {useSelf, useViewWorkshop} from '../../../services/query/modules';
import {MiroColor, MiroGrey, PenAlt} from '../../../assets';
import {useLocalizedNavigation, useModal} from '../../../hooks';
import {RecipeDetail} from '../../RecipeDetail';
import {LocalizedText} from 'components/LocalizedText';
import {useIntl} from 'react-intl';
import {WorkshopActions} from 'pages/WorkshopsPage/components';

interface WorkshopLibraryTileProps {
  workshop: ToolboxObject.Workshop;
}

export const WorkshopLibraryTile: React.FC<WorkshopLibraryTileProps> = ({workshop}) => {
  const intl = useIntl();

  const self = useSelf();
  const {isOpen, close, open} = useModal('workshop', workshop.id.toString());
  const {mutate: view} = useViewWorkshop(workshop.id);
  const navigate = useLocalizedNavigation();
  const workshopDuration = useMemo(() => {
    if (!workshop || !workshop.sessions) {
      return 0;
    }
    return workshop.sessions.reduce((sum, session) => {
      if (!session.editable_methods) {
        return sum ?? 0;
      }
      return (
        sum +
        (session.editable_methods.reduce((innerSum, method) => {
          return innerSum + (method.duration ?? 0);
        }, 0) ?? 0)
      );
    }, 0);
  }, [workshop]);
  const onCard = () => {
    open();
    view();
  };

  const onEditor = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    navigate(`/editor/${workshop.id}`);
  };

  const lastEdited = intl.formatMessage({id: 'pages.library.method.lastEdited'}, {date: dayjs(workshop.user_data?.last_updated).format('DD MMM YYYY')});
  return (
    <>
      <Modal onClose={close} isOpen={isOpen}>
        <RecipeDetail recipe={workshop} duration={workshopDuration} />
      </Modal>
      <div className="group">
        <div
          className="flex flex-col justify-between rounded-xl border-solid border border-black shadow-hard bg-white
    group-hover:shadow-pop group-hover:mt-[-2px] group-hover:mb-[2px] cursor-pointer
    transition-all font-['Inter'] group w-full h-full break-words overflow-visible"
          onClick={onCard}
          aria-hidden
        >
          <div className="flex flex-col h-full rounded-r-xl gap-3 px-4 py-3 w-full justify-between">
            <div className="flex justify-between items-center mb-8">
              <Title text={workshop.title} />
              {self.data?.role && (
                <WorkshopActions
                  userRole={self.data?.role}
                  workshopId={workshop.id}
                  isReadyOnly={!workshop?.user_data?.is_owner && workshop?.user_data?.access === 'read'}
                  isLibrary
                  plainButton
                />
              )}
            </div>
            <Footer duration={workshopDuration} hideExtras text={lastEdited} capitalizeText={false} />
          </div>
          <div className="border-t border-solid border-white-40">
            <div className="flex justify-around items-center h-10">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div className="w-[50%] flex justify-center items-center text-center gap-2 m-2" onClick={onEditor}>
                <PenAlt />
                <p className="text-sm font-medium leading-5">
                  <LocalizedText id="pages.library.method.openEditor" />
                </p>
              </div>
              <hr className="h-10 w-0 border border-solid border-white-40 m-2" />
              <div
                className="w-[50%] flex justify-center items-center text-center gap-2 m-2"
                onClick={(e) => {
                  return e.stopPropagation();
                }}
                aria-hidden
              >
                {workshop.miro_href ? (
                  <button
                    type="button"
                    role="link"
                    onClick={() => {
                      return window.open(workshop.miro_href ?? '/', '_blank', 'noreferrer');
                    }}
                    className="inline-flex w-full justify-center bg-white text-sm font-medium text-black hover:bg-gray-50"
                  >
                    <MiroColor />
                    <p className="pl-2 mt-0.5">
                      <LocalizedText id="pages.library.method.openInMiro" />
                    </p>
                  </button>
                ) : (
                  <button type="button" className="flex justify-center items-center gap-2">
                    <MiroGrey />
                    <p className="text-sm font-medium leading-5 text-black/[0.4]">
                      <LocalizedText id="pages.library.method.openInMiro" />
                    </p>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
