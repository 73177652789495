import React from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Button, Col, Form, Row, Space} from 'antd';
import {AITranslation, TextEditor} from 'components';
import {SUPPORTED_LANGUAGES} from 'constants/app.constants';
import {useIntl} from 'react-intl';
import {toast} from 'react-toastify';
import {upperFirst} from 'services';
import {useUpsertMethodTranlsation} from 'services/query/modules';

const getMethodTranslatableFields: (method: ToolboxObject.Method) => {name: keyof ToolboxObject.MethodDetails; disabled?: boolean}[] = (method) => [
  {
    name: 'title',
  },
  {name: 'teaser'},
  {name: 'description', disabled: method.is_break},
];

interface AdminMethodTranslationFieldProps {
  name: keyof ToolboxObject.MethodDetails;
  method: ToolboxObject.MethodDetails;
  disabled?: boolean;
}

const AdminMethodTranslationField: React.FC<AdminMethodTranslationFieldProps> = ({method, name, disabled}) => {
  const intl = useIntl();
  const [form] = Form.useForm<ToolboxObject.Translation>();
  const methodTranslation = method.translations.find(({method_key}) => method_key === name);

  React.useEffect(() => {
    if (form) {
      SUPPORTED_LANGUAGES.forEach((lang) => {
        form.setFieldValue(lang, methodTranslation?.translation[lang as keyof ToolboxObject.Translation] || '');
      });
    }
  }, [form, methodTranslation, name]);

  const {mutate: upsertMethodTranslation, isSuccess, isLoading} = useUpsertMethodTranlsation(method.id);

  React.useEffect(() => {
    if (isSuccess) {
      toast.success(`${name} translation saved successfully`);
    }
  }, [isSuccess]);

  const onFinish = (values: ToolboxObject.Translation) => {
    const data = {
      itemId: name,
      translation: {...values, translation_key: methodTranslation?.translation_key},
    };

    upsertMethodTranslation(data);
  };
  if (disabled) {
    return null;
  }
  return (
    <Form form={form} layout="vertical" disabled={false} onFinish={onFinish} name={name}>
      <Col>
        <Form.Item label={<p className="text-lg">Original {upperFirst(name)}</p>} name={name}>
          <TextEditor data={method[name]} disabled />
        </Form.Item>
        <Row>
          {SUPPORTED_LANGUAGES.map((lang) => (
            <Col key={`${name}${lang}`} md={{span: 12}} span={24}>
              <div className="flex flex-col p-4 m-4 border border-gray-200 rounded-xl bg-gray-100">
                <Form.Item
                  label={intl.formatMessage({id: `locale.${lang}`})}
                  name={lang}
                  valuePropName="data"
                  getValueFromEvent={(_: Event, editor: ClassicEditor) => editor.getData() || ''}
                >
                  <TextEditor autoFocus={false} data={form.getFieldValue(lang)} />
                </Form.Item>
                <AITranslation className="mt-4" message={method[name]} lang={lang} handleValue={(text) => form.setFieldValue(lang, text)} />
              </div>
            </Col>
          ))}
        </Row>
        <div className="flex justify-center w-full">
          <Button type="primary" className="mb-4" htmlType="submit" disabled={isLoading}>
            Save {name}
          </Button>
        </div>
        <div className="w-full h-[1px] bg-gray-200 mb-8" />
      </Col>
    </Form>
  );
};

interface GeneralMethodContentProps {
  method: ToolboxObject.MethodDetails;
}

export const GeneralMethodContent: React.FC<GeneralMethodContentProps> = ({method}) => {
  return (
    <Space direction="vertical" size="middle">
      {getMethodTranslatableFields(method).map(({disabled, name}) => (
        <AdminMethodTranslationField name={name} method={method} key={name} disabled={disabled} />
      ))}
    </Space>
  );
};
