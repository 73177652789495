import {useMutation, useQuery, UseQueryOptions} from '@tanstack/react-query';
import {createMethod, deleteMethod, getMethod, getMethods, getMethodSubcategories, toggleLikeMethod, updateMethod, viewMethod} from 'services/axios/modules';
import {MethodStatus} from 'types';
import {queryClient} from '..';
import produce from 'immer';

export const useMethods = (statuses?: MethodStatus[]) => {
  return useQuery<ToolboxObject.Method[]>(
    ['methods', {statuses}],
    () => {
      return getMethods(statuses).then((data) => data.sort((a, b) => a.name.localeCompare(b.name)));
    },
    {staleTime: 3600000, cacheTime: 3600000}
  );
};

export const useMethod = (id: number, options: UseQueryOptions<ToolboxObject.MethodDetails> = {}) => {
  return useQuery<ToolboxObject.MethodDetails>(
    ['methods', {id}],
    () => {
      return getMethod(id);
    },
    {staleTime: 300000, ...options} // 5 minutes
  );
};

export const useCreateMethod = () => {
  return useMutation({
    mutationFn: (data: FormData) => {
      return createMethod(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['methods']);
    },
  });
};

export const useDeleteMethod = (id: number) => {
  return useMutation({
    mutationFn: () => {
      return deleteMethod(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['methods']);
    },
  });
};

export const useUpdateMethod = (id: number) => {
  return useMutation({
    mutationFn: (data: FormData) => {
      return updateMethod(id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['methods']);
    },
  });
};

export const useMethodSubcategories = () => {
  return useQuery<ToolboxObject.Subcategory[]>(
    ['subcategories'],
    () => {
      return getMethodSubcategories();
    },
    {staleTime: 3600000, cacheTime: 3600000}
  );
};

export const useLikeMethod = (methodId: number, isLiked: boolean) => {
  return useMutation({
    mutationFn: () => {
      return toggleLikeMethod(methodId, isLiked);
    },
    onSuccess: (data) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {is_favorite, last_viewed} = data;
      return queryClient.setQueryData(['methods', {}], (oldMethods: ToolboxObject.Method[] | undefined) => {
        return produce(oldMethods, (draft: ToolboxObject.Method[]) => {
          const index = draft?.findIndex((method) => {
            return method.id === methodId;
          });
          // eslint-disable-next-line no-param-reassign
          if (index !== undefined && index !== -1 && draft) draft[index].user_data = {is_favorite, last_viewed};
        });
      });
    },
  });
};

export const useViewMethod = (methodId: number) => {
  return useMutation({
    mutationFn: () => {
      return viewMethod(methodId);
    },
    onSuccess: (data) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {is_favorite, last_viewed} = data;
      return queryClient.setQueryData(['methods', {}], (oldMethods: ToolboxObject.Method[] | undefined) => {
        return produce(oldMethods, (draft: ToolboxObject.Method[]) => {
          const index = draft?.findIndex((method) => {
            return method.id === methodId;
          });
          // eslint-disable-next-line no-param-reassign
          if (index !== undefined && index !== -1 && draft) draft[index].user_data = {is_favorite, last_viewed};
        });
      });
    },
  });
};
