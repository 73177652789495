import {useMutation} from '@tanstack/react-query';
import {deleteMethodAttachments, uploadMethodAttachments} from 'services/axios/modules';

export const useUploadMethodAttachments = () => {
  return useMutation({
    mutationFn: uploadMethodAttachments,
  });
};

export const useDeleteMethodAttachments = () => {
  return useMutation({
    mutationFn: deleteMethodAttachments,
  });
};
