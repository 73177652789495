import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Button, Col, Form, Row} from 'antd';
import {AITranslation, TextEditor} from 'components';
import {SUPPORTED_LANGUAGES} from 'constants/app.constants';
import React from 'react';
import {useIntl} from 'react-intl';
import {toast} from 'react-toastify';
import {upperFirst} from 'services';
import {
  useUpsertMethodInfoTranlsation,
  useUpsertMethodItemTranlsation,
  useUpsertMethodMaterialTranlsation,
  useUpsertMethodStepTranlsation,
} from 'services/query/modules';

interface DynamicMethodContentProps<T = ToolboxObject.MethodListItem> {
  list: T[];
  title: string;
  methodId: number;
  useUpsertQuery: (methodId: number) => ReturnType<typeof useUpsertMethodItemTranlsation<number>>;
}

interface DynamicMethodItemProps<T = ToolboxObject.MethodListItem> extends Omit<DynamicMethodContentProps, 'list'> {
  item: T;
  index: number;
  originalText: string;
}

const DynamicMethodItem: React.FC<DynamicMethodItemProps> = ({useUpsertQuery, methodId, item, title, index, originalText}) => {
  const intl = useIntl();
  const [form] = Form.useForm<ToolboxObject.Translation>();

  const {translation} = item;

  const {mutate: upsertMethodItemTranslation, isSuccess, isLoading} = useUpsertQuery(methodId);

  React.useEffect(() => {
    if (isSuccess) {
      toast.success(`${title} translation saved successfully`);
    }
  }, [isSuccess]);

  React.useEffect(() => {
    if (form) {
      if (translation) {
        SUPPORTED_LANGUAGES.forEach((lang) => {
          form.setFieldValue(lang, translation?.[lang as keyof ToolboxObject.Translation] || '');
        });
      }
    }
  }, [form, translation]);

  const onFinish = (values: ToolboxObject.Translation) => {
    const data = {
      itemId: item.id,
      translation: {...values, translation_key: translation?.key},
    };
    upsertMethodItemTranslation(data);
  };
  return (
    <Form form={form} layout="vertical" disabled={false} onFinish={onFinish}>
      <Col>
        <Form.Item label={<p className="text-lg">{`${index + 1}. ${upperFirst(title)}`}</p>} name={title}>
          <TextEditor data={originalText} disabled />
        </Form.Item>
        <Row>
          {SUPPORTED_LANGUAGES.map((lang) => (
            <Col key={`${title}${lang}`} md={{span: 12}} span={24}>
              <div className="flex flex-col p-4 m-4 border border-gray-200 rounded-xl bg-gray-100">
                <Form.Item
                  label={intl.formatMessage({id: `locale.${lang}`})}
                  name={lang}
                  valuePropName="data"
                  getValueFromEvent={(_: Event, editor: ClassicEditor) => editor.getData() || ''}
                >
                  <TextEditor autoFocus={false} data={form.getFieldValue(lang)} />
                </Form.Item>
                <AITranslation className="mt-4" message={originalText} lang={lang} handleValue={(text) => form.setFieldValue(lang, text)} />
              </div>
            </Col>
          ))}
        </Row>
        <div className="flex justify-center w-full">
          <Button type="primary" className="mb-4" htmlType="submit" disabled={isLoading}>
            Save
          </Button>
        </div>
        <div className="w-full h-[1px] bg-gray-200 mb-8" />
      </Col>
    </Form>
  );
};

type MethodItemProps<T> = React.FC<Omit<DynamicMethodItemProps<T>, 'useUpsertQuery' | 'originalText'>>;

const MethodInfoItem: MethodItemProps<ToolboxObject.Info> = (props) => {
  return <DynamicMethodItem useUpsertQuery={useUpsertMethodInfoTranlsation} originalText={props.item.description} {...props} />;
};

const MethodMaterialItem: MethodItemProps<ToolboxObject.Material> = (props) => {
  return <DynamicMethodItem useUpsertQuery={useUpsertMethodMaterialTranlsation} originalText={props.item.name} {...props} />;
};

const MethodStepItem: MethodItemProps<ToolboxObject.Step> = (props) => {
  return <DynamicMethodItem useUpsertQuery={useUpsertMethodStepTranlsation} originalText={props.item.description} {...props} />;
};

type MethodItemListProps<T> = React.FC<Omit<DynamicMethodContentProps<T>, 'useUpsertQuery' | 'originalText'>>;

export const MethodInfoItemList: MethodItemListProps<ToolboxObject.Info> = ({list, ...rest}) => {
  return (
    <>
      {list.map((item, index) => (
        <MethodInfoItem key={item.id} item={item} index={index} {...rest} />
      ))}
    </>
  );
};

export const MethodMaterialItemList: MethodItemListProps<ToolboxObject.Material> = ({list, ...rest}) => {
  return (
    <>
      {list.map((item, index) => (
        <MethodMaterialItem key={item.id} item={item} index={index} {...rest} />
      ))}
    </>
  );
};

export const MethodStepItemList: MethodItemListProps<ToolboxObject.Step> = ({list, ...rest}) => {
  return (
    <>
      {list.map((item, index) => (
        <MethodStepItem key={item.id} item={item} index={index} {...rest} />
      ))}
    </>
  );
};
