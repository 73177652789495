import {request} from '..';

export const getNotifications = () => {
  return request<ToolboxObject.Notification[]>({
    url: '/notifications',
    method: 'GET',
  });
};

export const updateNotifications = (data: Partial<Omit<ToolboxObject.Notification, 'id'>> & {notificationIds: number[]}) => {
  return request<ToolboxObject.Notification[]>({
    url: '/notifications',
    method: 'PUT',
    data,
  });
};
